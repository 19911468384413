<template>
	<b-row>
		<b-col sm="3" v-if="btnView">
			<b-button
				variant="outline-primary"
				class="btn-icon btn-sm"
				@click="click(data, 'clickView')"
				title="View"
			>
				<feather-icon icon="SearchIcon" />
			</b-button>
		</b-col>
		<b-col sm="3" v-if="btnEdit">
			<b-button
				variant="outline-warning"
				class="btn-icon btn-sm"
				@click="click(data, 'clickEdit')"
				title="Edit"
			>
				<feather-icon icon="Edit2Icon" />
			</b-button>
		</b-col>
		<b-col sm="2" v-if="btnDelete">
			<b-button
				variant="outline-danger"
				class="btn-icon btn-sm"
				@click="click(data, 'clickDelete')"
				title="Delete"
			>
				<feather-icon icon="Trash2Icon" />
			</b-button>
		</b-col>
		<b-col sm="3" v-if="btnCustom">
			<b-button
				variant="outline-info"
				class="btn-icon "
				@click="click(data, 'clickCustom')"
				:title="btnCustomTitle"
			>
				{{ btnCustomTitle }}
			</b-button>
		</b-col>
	</b-row>
</template>
<script>
import { mapState } from 'vuex'
export default {
	computed: {
		...mapState('tableAPI', [
			'btnView',
			'btnEdit',
			'btnDelete',
			'btnCustom',
			'btnCustomTitle',
		]),
	},
	props: {
		data: {},
		name: {},
		click: {
			type: Function,
			default: () => {},
		},
		classes: {
			type: Object,
			default: () => ({
				btn: true,
				'btn-primary': true,
				'btn-sm': true,
			}),
		},
	},
}
</script>
